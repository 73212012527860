import React, { useState, useEffect } from 'react';

import Receipt from '../../db/Receipt'
import Database from '../../db/Database';

interface NewEditReceiptPageProps {
  receiptId?: number,
  toClone?: boolean
  database: Database
}

const NewEditReceiptPage = (props: NewEditReceiptPageProps) => {

  const database = props.database;
  const [receipt, setReceipt] = useState<any>(new Receipt());
  const [screenMode, setScreenMode] = useState("create")

  useEffect(() => {
    if (props.receiptId) {
      if (props.toClone) {
        setScreenMode("clone");
      } else {
        setScreenMode("edit");
      }

      database.getReceipt(props.receiptId)
        .then((receipt) => {
          setReceipt(receipt);
        })
        .catch((exception) => {
          alert("Não foi possível carregar o recibo.");
          console.log(exception);
        });
    } else {
      setScreenMode("create");
    }
  }, [props.receiptId]);
  
  const cancel = function() {
    window.location.hash = "#receipt-list";
  };

  const done = async function() {
    const receiptModel = new Receipt();
    
    receiptModel.payerName = receipt.payerName;
    receiptModel.payerAddress = receipt.payerAddress;
    receiptModel.referrerTo = receipt.referrerTo;
    receiptModel.paidValue = receipt.paidValue;
    receiptModel.date = receipt.date;
    receiptModel.payeeName = receipt.payeeName;
    receiptModel.payeeCpfOrRg = receipt.payeeCpfOrRg;
    receiptModel.payeeAddress = receipt.payeeAddress;

    if (screenMode === "clone") {
      await database.addReceipt(receiptModel);
    }
    if (screenMode === "edit") {
      await database.updateReceipt(receiptModel, props.receiptId!);
    }
    if (screenMode === "create") {
      await database.addReceipt(receiptModel);
    }
    window.location.hash = "#receipt-list";
  };

  const handleChange = function(e: any) {
    const {name, value} = e.target;

    setReceipt((prevState: any) => {
      let receipt = { ...prevState }; 
      receipt[name] = value;
      return receipt;
    })
  };

  return (
    <div className="">
      <div className="flex flex-col pb-2">
        <div className="navbar shadow-lg bg-neutral text-neutral-content rounded-box">
          
          <div className="flex-none">
            <button className="btn btn-square btn-ghost" onClick={() => (window.history.back())}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
            </svg>
            </button>
          </div>

          <div className="flex-1 text-2xl">
            {screenMode==="create" && "Criando"}
            {screenMode==="edit" && "Editando nº " + receipt.id}
            {screenMode==="clone" && "Clonando nº " +  + receipt.id}
          </div>

          {screenMode=="create" && (
          <div className="flex-none">
            <button className="btn btn-square btn-ghost" onClick={() => cancel()}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-6 h-6 stroke-current text-error">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
            </button>
          </div>
          )}
        </div>
      </div>
      
      <form className="receipt-form" name="receipt-form" 
        action="javascript:void(0);"
        noValidate >

        <div className="receipt-payer mb-4">
                    
          <h5 className="text-xl">Recebido de</h5>

          <div className="form-control">
            <label className="label" htmlFor={"payerName"}>
              <span className="label-text">Recebi (emos) de</span>
            </label>
            <input 
              className="input input-bordered" 
              type="text"   
              name={"payerName"} 
              placeholder="" 
              id={"payerName"}
              value={receipt.payerName} 
              onChange={handleChange}
              autoFocus/>
          </div>
            

          <div className="form-control">
            <label className="label" htmlFor={"referrerTo"}>
              <span className="label-text">Referente</span>
            </label>
            <input 
              className="input input-bordered" 
              type="text"   
              name={"referrerTo" } 
              placeholder="" 
              id={"referrerTo" }
              value={receipt.referrerTo} 
              onChange={handleChange}
              />
          </div>

          <div className="form-control">
            <label className="label" htmlFor={"paidValue"}>
              <span className="label-text">Valor</span>
            </label>
            <input 
              className="input input-bordered" 
              type="number"   
              name={"paidValue"} 
              placeholder="" 
              id={"paidValue"}
              value={receipt.paidValue} 
              onChange={handleChange}
              />
          </div>

          <div className="form-control">
            <label className="label" htmlFor={"payerAddress"}>
              <span className="label-text">Endereço</span>
            </label>
            <input 
              className="input input-bordered" 
              type="text"   
              name={"payerAddress"} 
              placeholder="" 
              id={"payerAddress"}
              value={receipt.payerAddress} 
              onChange={handleChange}
              />
          </div>
        </div>

        <div className="form-control mb-4">
          <h5 className="text-xl">Data</h5>
          <input 
            className="input input-bordered" 
            type="date"  
            name={"date"} 
            placeholder="" 
            id={"date"}
            value={receipt.date} 
            onChange={handleChange}
            />
        </div>

        
        <div className="mb-4">
          <h5 className="text-xl">Recebido por</h5>

          <div className="form-control">
            <label className="label" htmlFor={"payeeName"}>
              <span className="label-text">Emitente</span>
            </label>
            <input 
              className="input input-bordered" 
              type="text" 
              name={"payeeName"} 
              placeholder="" 
              id={"payeeName"}
              value={receipt.payeeName}
              onChange={handleChange}
              />
          </div>

          <div className="form-control">
            <label className="label" htmlFor={"payeeCpfOrRg"}>
              <span className="label-text">CPF/RG</span>
            </label>
            <input 
              className="input input-bordered" 
              type="text"   
              name={"payeeCpfOrRg"} 
              placeholder="" 
              id={"payeeCpfOrRg"}
              value={receipt.payeeCpfOrRg} 
              onChange={handleChange}
              />
          </div>

          <div className="form-control">
            <label className="label" htmlFor={"payeeAddress"}>
              <span className="label-text">Endereço</span>
            </label>
            <input 
              className="input input-bordered" 
              type="text"   
              name={"payeeAddress"} 
              placeholder="" 
              id={"payeeAddress"}
              value={receipt.payeeAddress} 
              onChange={handleChange}
              />
          </div>
        </div>

        <button
          className="btn btn-block pt-2"
          onClick={() => done()}>
          Pronto
        </button>
      </form>

    </div>
  );
}

export default NewEditReceiptPage;