import React from 'react';
import './Modal.css';

interface ModalProps {
  handleClose: any;
  children: any;
  show?: boolean;
};

const Modal = (props: ModalProps) => {
  const showHideClassName = props.show ? "modal-custom" : "modal-custom hidden";

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        {props.children}
        <a onClick={props.handleClose}>Cancelar</a>
      </section>
    </div>
  );
};

export default Modal;