import React from 'react';
import { observer } from "mobx-react";
import {moneyToWords} from '../../utils/MoneyToWords'

import './Receipt.css';

const Receipt = observer((props) => {

    return (
      <div className="receipt-container">
        
        <div className="flex items-center">
          <span className="flex-none text-xl font-semibold">RECIBO</span>
        
          <div className="flex-1">
            <div className="flex justify-center">
              <label>Numero</label>
              <span>{props.receipt.id}</span>
            </div>
          </div>
          
          <div className="flex-none">
            <label>Valor</label>
            <span>R${props.receipt.paidValue}</span>
          </div>
        </div>

        <div className="receipt-payer">
          <div>
            <label>Recebi (emos) de</label>
            <span>{props.receipt.payerName}</span>
          </div>

          <div>
            <label>Endereço</label>
            <span>{props.receipt.payerAddress}</span>
          </div>

          <div>
            <label>A importância de</label>
            {props.receipt.paidValue && 
              <span>{moneyToWords(props.receipt.paidValue)}</span>
            }
          </div>

          <div>
            <label>Referente</label>
            <span>{props.receipt.referrerTo}</span>
          </div>
        </div>

        <div className="receipt-date">
          <label>Data</label>
          {props.date}
        </div>

        <div className="receipt-payee">
          <div>
            <label>Emitente</label>
            <span>{props.payee}</span>
          </div>
          <div>
            <label>CPF/RG</label>
            <span>{props.payeeCpfOrRg}</span>
          </div>

          <div>
            <label>Endereço</label>
            <span>{props.payeeAddress}</span>
          </div>

          <div>
            <label>Assinatura</label>
            <span></span>
          </div>
        </div>
      </div>
    );
  }
)
export default Receipt;