import React, {useState, useEffect} from 'react';


interface SettingsPageProps {
}

const SettingsPage = (props: SettingsPageProps) => {

  return (
   <div>
     <div className="flex flex-col pb-2">
        <div className="navbar shadow-lg bg-neutral text-neutral-content rounded-box">
          
          <div className="flex-none">
            <button className="btn btn-square btn-ghost" onClick={() => (window.history.back())}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
            </svg>
            </button>
          </div>

          <div className="flex-1 text-2xl">
            Configurações
          </div>
        </div>
      </div>

      <button 
        className="btn btn-block"
        onClick={() => window.location.hash = "#multi-edition-settings"}>
        Configurar campos de edição multipla.
      </button>
   </div>
  )
}

export default SettingsPage;