class Receipt {
  id: number | undefined;
  payerName?: string;
  payerAddress?: string;
  referrerTo?: string;
  paidValue?: number;
  // YYYY-MM-DD
  date?: string;
  payeeName?: string;
  payeeCpfOrRg?: string;
  payeeAddress?: string;

  get dateAsTimeStamp() {
    if (this.date) {
      const d: Date = new Date(this.date);
      return d.getTime();
    }
    return 9007199254740991;
  }

  get yearAndMonth() {
    if (this.date) {
      return this.date.substr(0,7);
    }
    return undefined;
  }

  get yearMonthAndDay() {
    if (this.date) {
      return this.date;
    }
    return undefined;
  }
}


export default Receipt;