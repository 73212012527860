const monthAsTextMap:any = {
  0: 'Janeiro',
  1: 'Fevereiro',
  2: 'Março',
  3: 'Abril',
  4: 'Maio',
  5: 'Junho',
  6: 'Julho',
  7: 'Agosto',
  8: 'Setembro',
  9: 'Outubro',
  10: 'Novembro',
  11: 'Dezembro'
}

// Formated as 2021-08-20
function friendlyYearAndMonthAsText(dateAsText: string) {
  if (!dateAsText) {
    return undefined;
  }

  const date = new Date(dateAsText);
  const year = date.getUTCFullYear();
  const literalMonth = monthAsTextMap[date.getUTCMonth()];
  if ((new Date()).getUTCFullYear() === year) {
    return literalMonth;
  } else {
    return literalMonth + ' de ' + year;
  }
}

function dateToYYYYMMDD(date: Date) {
  let month = '' + (date.getUTCMonth() + 1);
  let day = '' + date.getDate();
  let year = date.getUTCFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

export {dateToYYYYMMDD}
export default friendlyYearAndMonthAsText;