import {dateToYYYYMMDD} from './FriendlyLiteralDate';

const dateCurrentMonth = (new Date());
dateCurrentMonth.setDate(1);

const dateLastMonth = (new Date());
dateLastMonth.setDate(1);
if (dateLastMonth.getMonth() > 0) {
  dateLastMonth.setMonth(dateLastMonth.getMonth()  - 1);
} else {
  dateLastMonth.setMonth(11);
  dateLastMonth.setFullYear(dateLastMonth.getFullYear() - 1);
}

const forCurrentMonth = dateToYYYYMMDD(dateCurrentMonth);
const forLastMonth = dateToYYYYMMDD(dateLastMonth);


const receiptsOfCurrentMonth = [
  {
    payerName: "Exemplo - João Francisco",
    payerAddress: "Rua Capitão Júnio, 222, apto 301, Belo Horizonte",
    referrerTo: "4 sessões de terapia",
    paidValue: 600,
    paidValueAsText: "",
    date: forCurrentMonth,
    payeeName: "Rafael Pereira",
    payeeCpfOrRg: "123456789-01",
    payeeAddress: "Rua Martinho Campos, 1212, Belo Horizonte"
  },
  {
    payerName: "Exemplo - Pedro Ferreira",
    payerAddress: "Rua Capitolio, 334, Belo Horizonte",
    referrerTo: "2 treinamentos de pais",
    paidValue: 300,
    paidValueAsText: "",
    date: forCurrentMonth,
    payeeName: "Rafael Pereira",
    payeeCpfOrRg: "123456789-01",
    payeeAddress: "Rua Martinho Campos, 1212, Belo Horizonte"
  },
  {
    payerName: "Exemplo - José Augusto",
    payerAddress: "Rua Almirante Neves, 4455, Belo Horizonte",
    referrerTo: "8 sessões de terapia",
    paidValue: 1200,
    paidValueAsText: "",
    date: forCurrentMonth,
    payeeName: "Rafael Pereira",
    payeeCpfOrRg: "123456789-01",
    payeeAddress: "Rua Martinho Campos, 1212, Belo Horizonte"
  },
  {
    payerName: "Exemplo - Auguto Ferraz",
    payerAddress: "Rua dos Otonis, 908, Belo Horizonte",
    referrerTo: "4 sessões de terapia",
    paidValue: 600,
    paidValueAsText: "",
    date: forCurrentMonth,
    payeeName: "Rafael Pereira",
    payeeCpfOrRg: "123456789-01",
    payeeAddress: "Rua Martinho Campos, 1212, Belo Horizonte"
  }
]

const receiptsOfLastMonth = [
  {
    payerName: "Exemplo - João Francisco",
    payerAddress: "Rua Capitão Júnio, 222, apto 301, Belo Horizonte",
    referrerTo: "5 sessões de terapia",
    paidValue: 750,
    paidValueAsText: "",
    date: forLastMonth,
    payeeName: "Rafael Pereira",
    payeeCpfOrRg: "123456789-01",
    payeeAddress: "Rua Martinho Campos, 1212, Belo Horizonte"
  },
  {
    payerName: "Exemplo - Pedro Ferreira",
    payerAddress: "Rua Capitolio, 334, Belo Horizonte",
    referrerTo: "2 treinamentos de pais",
    paidValue: 300,
    paidValueAsText: "",
    date: forLastMonth,
    payeeName: "Rafael Pereira",
    payeeCpfOrRg: "123456789-01",
    payeeAddress: "Rua Martinho Campos, 1212, Belo Horizonte"
  },
  {
    payerName: "Exemplo - José Augusto",
    payerAddress: "Rua Almirante Neves, 4455, Belo Horizonte",
    referrerTo: "4 sessões de terapia",
    paidValue: 600,
    paidValueAsText: "",
    date: forLastMonth,
    payeeName: "Rafael Pereira",
    payeeCpfOrRg: "123456789-01",
    payeeAddress: "Rua Martinho Campos, 1212, Belo Horizonte"
  },
  {
    payerName: "Exemplo - Auguto Ferraz",
    payerAddress: "Rua dos Otonis, 908, Belo Horizonte",
    referrerTo: "3 sessões de terapia",
    paidValue: 450,
    paidValueAsText: "",
    date: forLastMonth,
    payeeName: "Rafael Pereira",
    payeeCpfOrRg: "123456789-01",
    payeeAddress: "Rua Martinho Campos, 1212, Belo Horizonte"
  },
  {
    payerName: "Exemplo - Carlos Lopes",
    payerAddress: "Rua Primavera, 431, Belo Horizonte",
    referrerTo: "5 sessões de terapia",
    paidValue: 750,
    paidValueAsText: "",
    date: forLastMonth,
    payeeName: "Rafael Pereira",
    payeeCpfOrRg: "123456789-01",
    payeeAddress: "Rua Martinho Campos, 1212, Belo Horizonte"
  }
]

receiptsOfLastMonth.push();

const receiptAsExamples = receiptsOfCurrentMonth.concat(receiptsOfLastMonth);

export {receiptAsExamples}