import React, { useState, useEffect } from 'react';

import './ListReceiptCardPage.css';

import Receipt  from '../../db/Receipt';
import friendlyYearAndMonthAsText from '../../db/FriendlyLiteralDate';
import ByMonthReceiptCard from './ByMonthReceiptCard';
import ListReceiptCardHeader from './ListReceiptCardHeader';

const ReceiptCardsPage = (props: any) => {
  const database = props.database;
  const [receiptsByMonth, setReceiptsByMonth] = useState<Map<string, [Receipt]>>();
  const [receiptsSelected, setReceiptSelected] = useState<Map<number, Receipt>>(new Map());

  const [selectMode, setSelectMode] = useState<boolean>(false);
  
  function handleSelectAll(receipts: Receipt[]) {
    const a = new Map(receiptsSelected);
    receipts.map((receipt) => {
      a.set(receipt.id!, receipt);
    })
    setReceiptSelected(a);
    setSelectMode(true);
  }

  function handleSelectionChange(receiptId: number, receipt: Receipt | undefined) {
    if (receipt) {
      setReceiptSelected(new Map(receiptsSelected).set(receiptId, receipt));
    } else {
      const a = new Map(receiptsSelected);
      a.delete(receiptId);
      setReceiptSelected(a);
    }
  }

  function handleCloseSelectionMode() {
    setSelectMode(false);
    setReceiptSelected(new Map());
  }

  function handleClickPrint() {
    const i = receiptsSelected.keys();
    
    let idNext = i.next();
    let receiptIds = new Array<number>();
    while (!idNext.done) {
      receiptIds.push(idNext.value);
      idNext = i.next();
    }
    window.location.hash = `receipts-to-print?receiptIds=${JSON.stringify(receiptIds)}`;
  }

  function handleDeleteSelection() {
    const answer = window.confirm(`Confirma a deleção de ${receiptsSelected.size} recibos?`);
    if (answer === true) {
      database.batchDelete(receiptsSelected.keys())
        .then(async() => {
          fetchReceipts();
          handleCloseSelectionMode();
        })
        .catch((exception: any) => {
          alert("Não foi possível deletar os recibos por um erro inesperado.");
          console.log(exception);
        });
    }
  }

  function handleClickToEditMultiple() {
    const i = receiptsSelected.keys();
    
    let idNext = i.next();
    let receiptIds = new Array<number>();
    while (!idNext.done) {
      receiptIds.push(idNext.value);
      idNext = i.next();
    }
    window.location.hash = `#multi-edition?receiptIds=${JSON.stringify(receiptIds)}`;
  }
  
  function handleCloneSelection() {
    const receipts = receiptsSelected.values();
    const answer = window.confirm(`Confirma a duplicação de ${receiptsSelected.size} recibos?`);
    if (answer === true) {
      const receiptsToClone: Receipt[] = [];
      let nextReceipt = receipts.next();
      while (!nextReceipt.done) {
        const receipt: Receipt = nextReceipt.value;
        //remove date to show clones at the top
        receipt.date = undefined;
        receiptsToClone.push(receipt)
        nextReceipt = receipts.next();
      }
  
      database.arrayAdd(receiptsToClone)
        .then(async() => {
          fetchReceipts();
          handleCloseSelectionMode();
        })
        .catch((exception: any) => {
          alert("Não foi possível clonar os recibo selecionados.");
          console.log(exception);
        });
    }
  }

  useEffect(() => {
    fetchReceipts();
  }, []);
  
  async function fetchReceipts() {
    const receipts: Receipt[] = await database.getAllReceipts();
    receipts.sort((r1, r2) => r2.dateAsTimeStamp - r1.dateAsTimeStamp)

    const rByMonth = receipts.reduce((byMonth, r) => {
      const array: [Receipt] = byMonth.get(r.yearAndMonth) || [];
      array.push(r);
      byMonth.set(r.yearAndMonth, array);
      return byMonth;
    }, new Map())

    setReceiptsByMonth(rByMonth)
  };
  const[payerNameFiltering, setPayerNameFiltering] = useState("");

  function handleRemoveReceipt(id: number) {
    // TODO GOOD CASE FOR UNIT TEST, SUCCESS MEAN DELETED AND NOT FOUND??
    database.deleteReceipt(id)
      .then(async() => {
        fetchReceipts();
      })
      .catch((exception: any) => {
        alert("Não foi possível deletar o recibo por um erro inesperado.");
        console.log(exception);
      })
  }

  return (
    <div className="receipts-card">

      <ListReceiptCardHeader
        isSelectModeOn={selectMode}
        onChangeOfPayerNameToFilter={setPayerNameFiltering}
        onClickToCloneSelectedReceipts={handleCloneSelection}
        onClickToDeleteSelectedReceipts={handleDeleteSelection}
        onClickToDisableSelectMode={handleCloseSelectionMode}
        onClickToEnableSelectMode={() => setSelectMode(true)} 
        onClickToPrintSelectedReceipts={() => handleClickPrint()}
        onClickToEditSelectedReceipts={() => handleClickToEditMultiple()}
        receiptsSelected={receiptsSelected}
      />

      {
        receiptsByMonth && [...receiptsByMonth.keys()].map((keyName, index) => {

          const receipts: Receipt[] = receiptsByMonth.get(keyName) || [];
          const friendlyTitle = friendlyYearAndMonthAsText(keyName) || "Área de trabalho (sem data ou clonado)";
          
          return (
            <ByMonthReceiptCard
              title={friendlyTitle}
              receipts={receipts}
              enableSelection={selectMode}
              receiptsSelected={receiptsSelected}
              payerNameFiltering={payerNameFiltering}
              onRemove={handleRemoveReceipt}
              onSelectionChange={handleSelectionChange}
              onSelectAll={handleSelectAll}
            />
          )
        })
      } 
    </div>
  );
}

export default ReceiptCardsPage;