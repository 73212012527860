import React from 'react';

interface ReceiptFormInputProps {
  id: string,
  label: string,
  value: string,
  type: string,
  onChange(e: any): void
  readonly?: boolean
}

const ReceiptFormInput = (props: ReceiptFormInputProps) => {
  return (
    <div>
      {!props.readonly && (
        <div className="form-control">
          <label className="label" htmlFor={props.id}>
            <span className="label-text">{props.label}</span>
          </label>
          <input 
          className="input input-bordered" 
          type={props.type}   
          name={props.id} 
          placeholder="" 
          id={props.id}
          value={props.value} 
          onChange={props.onChange}
          autoFocus
          />
        </div>
      )}

      {props.readonly && (
        <div>
          <label htmlFor={props.id}>{props.label}: </label>
          <span className="font-semibold">{props.value}</span>
        </div>
      )}
    </div>
  )
}

export default ReceiptFormInput;