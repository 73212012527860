import React, { useState, useEffect } from 'react';
import Receipt from '../../db/Receipt';
import ReceiptFormInput from './ReceiptFormInput';
import { Field, Setting } from '../../pages/MultiEditionSettings/MultiEditionSettingsPage';

interface ReceiptFormProps {
  receipt: Receipt;
  onChange(receipt: Receipt): void;
  fieldsToIdentify: Array<Field>;
  fieldsToUpdate: Array<Field>;
}

const ReceiptForm = (props: ReceiptFormProps) => {
  const buildAndSetMapFieldsData = (receipt: Receipt) => {
    return new Map<Field, any>([
      [Field.PayerName, {id: "payerName", label: "Recebi (emos) de", value: receipt.payerName, type: "text"}],
      [Field.ReferrerTo, {id: "referrerTo", label: "Referente", value: receipt.referrerTo, type: "text"}],
      [Field.PaidValue, {id: "paidValue", label: "Valor", value: receipt.paidValue, type: "text"}],
      [Field.PayerAddress, {id: "payerAddress", label: "Endereço", value: receipt.payerAddress, type: "text"}],
      [Field.Date, {id: "date", label: "Data", value: receipt.date, type: "date"}],
      [Field.PayeeName, {id: "payeeName", label: "Emitente", value: receipt.payeeName, type: "text"}],
      [Field.PayeeCpfOrRg, {id: "payeeCpfOrRg", label: "CPF/RG", value: receipt.payeeCpfOrRg, type: "text"}]
    ]);
  }

  const [receipt, setReceipt] = useState<any>(props.receipt || new Receipt());
  const [mapFieldsData, setMapFieldsData] = useState<Map<Field, any>>(buildAndSetMapFieldsData(receipt));


  useEffect(() => {
    setReceipt(props.receipt);
    setMapFieldsData(buildAndSetMapFieldsData(props.receipt))
  }, [props.receipt.id])
  
  const handleChange = function(e: any) {
    const {name, value} = e.target;

    setReceipt((prevState: any) => {
      let receipt = { ...prevState }; 
      receipt[name] = value;

      const receiptModel = new Receipt();
      receiptModel.id = receipt.id;
      receiptModel.payerName = receipt.payerName;
      receiptModel.payerAddress = receipt.payerAddress;
      receiptModel.referrerTo = receipt.referrerTo;
      receiptModel.paidValue = receipt.paidValue;
      receiptModel.date = receipt.date;
      receiptModel.payeeName = receipt.payeeName;
      receiptModel.payeeCpfOrRg = receipt.payeeCpfOrRg;
      receiptModel.payeeAddress = receipt.payeeAddress;

      setMapFieldsData(buildAndSetMapFieldsData(receipt));
      
      props.onChange(receiptModel);

      return receipt;
    })
  };

  function getField(field: Field, readonly: boolean) {
    const fieldData = mapFieldsData.get(field);
    
    return (
      <ReceiptFormInput
        id={fieldData.id}
        label={fieldData.label}
        value={fieldData.value || ""}
        type={fieldData.type}
        onChange={handleChange}
        readonly={readonly}
      />
    )
  }

  return (
    <form className="receipt-form" name="receipt-form" 
      action="javascript:void(0);"
      noValidate >
     
      <div className="mb-4">
        {props.fieldsToIdentify.map(function(field: Field) {
          return getField(field, true);
        })}
      </div>

      <div className="mb-4">
        {props.fieldsToUpdate.map(function(field: Field) {
          return getField(field, false);
        })}
      </div>
    </form>
  )
};

export default ReceiptForm;