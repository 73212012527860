import React, {useState, useEffect} from 'react';
import Database from '../../db/Database';
import ReceiptForm from '../../components/receiptform/ReceiptForm';
import Receipt from '../../db/Receipt';
import MultiEditionSettings from '../../db/MultiEditionSettings'

interface MultiEditionPageProps {
  database: Database;
  receiptIds: [number];
  currentIndex: number;
}

const MultiEditionPage = (props: MultiEditionPageProps) => {
  const [loading, setLoading] = useState(true);

  const [receipt, setReceipt] = useState<Receipt>();
  const [multiEditionSettings, setMultiEditionSettings] = 
    useState<MultiEditionSettings>(new MultiEditionSettings());

  useEffect(() => {

    props.database.getMultiEditionSettings()
      .then((multiEditionSettings) => {
        setMultiEditionSettings(multiEditionSettings);
      })
      .catch((err) => {
        console.error('Fail to load fieldsSettings', err);
      })

    props.database.getReceipt(props.receiptIds[props.currentIndex])
      .then((receipt) => {
        setReceipt(receipt);
      })
      .catch((err) => {
        console.error('Fail to load fieldsSettings', err);
      })
      .finally(() => {
        setLoading(false);
      })
  }, [props.currentIndex])
  
  async function next() {
    await props.database.updateReceipt(receipt!, receipt!.id!);
    const nextIndex = props.currentIndex + 1;
    if (nextIndex < props.receiptIds.length) {
      window.location.hash = `#multi-edition?receiptIds=${JSON.stringify(props.receiptIds)}&current=${nextIndex}`;
    } else {
      window.location.hash = '#receipt-list';
    }
  }

  return (
    <div>
      <div className="flex flex-col pb-2">
        <div className="navbar shadow-lg bg-neutral text-neutral-content rounded-box">
          
          <div className="flex-none">
            <button className="btn btn-square btn-ghost" onClick={() => (window.history.back())}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
            </svg>
            </button>
          </div>

          <div className="flex-1 text-2xl">
            Editando {props.currentIndex + 1} de {props.receiptIds.length}
          </div>
        </div>
      </div>

      {loading && (
        <span>Carregando...</span>
      )}

      {!loading && (
        <div>
          <ReceiptForm
            receipt={receipt!}
            onChange={(receipt) => setReceipt(receipt)}
            fieldsToIdentify={multiEditionSettings.fieldsToIdentify()}
            fieldsToUpdate={multiEditionSettings.fieldsToUpdate()}
          />

          <button className="btn btn-block" onClick={next}>
            Salvar e próximo
          </button>
        </div>
      )}
   </div>
  )
  
  
};

export default MultiEditionPage;