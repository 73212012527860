
enum Field {
  ReceiptNumber = "ReceiptNumber",
  PayerName = "PayerName",
  ReferrerTo = "ReferrerTo",
  PaidValue = "PaidValue",
  PayerAddress = "PayerAddress",
  Date = "Date",
  PayeeName = "PayeeName",
  PayeeCpfOrRg = "PayeeCpfOrRg",
  PayeeAddress = "PayeeAddress"
}

enum Setting {
  None = "None",
  ToIdentify = "ToIdentify",
  ToUpdate = "ToUpdate"
}

class MultiEditionSettings {

  fieldSettingsMap: Map<Field, Setting>;
  
  static Instance(fieldSettings: Map<Field, Setting>): MultiEditionSettings {
    const multiEditionSettings = new MultiEditionSettings();
    multiEditionSettings.fieldSettingsMap = fieldSettings;
    return multiEditionSettings;
  }

  constructor() {
     this.fieldSettingsMap = new Map<Field, Setting>([
      [Field.ReceiptNumber, Setting.None],
      [Field.PayerName, Setting.ToIdentify],
      [Field.ReferrerTo, Setting.ToUpdate],
      [Field.PaidValue, Setting.ToUpdate],
      [Field.PayerAddress, Setting.None],
      [Field.Date, Setting.ToUpdate],
      [Field.PayeeName, Setting.None],
      [Field.PayeeCpfOrRg, Setting.None]
    ]);
  }


  get fieldSettings(): Map<Field, Setting> {
    return this.fieldSettingsMap;
  }

  getFieldSetting(field: Field): Setting | undefined {
    return this.fieldSettingsMap.get(field);
  }

  setFieldSetting(field: Field, setting: Setting) {
    this.fieldSettingsMap.set(field, setting);
  }

  fieldsToIdentify(): Array<Field> {
    const fieldsToIdentify = new Array<Field>();
    
    this.fieldSettingsMap.forEach((setting: Setting, field: Field) => {
      if (setting === Setting.ToIdentify) {
        fieldsToIdentify.push(field);
      }
    });

    return fieldsToIdentify;
  }

  fieldsToUpdate(): Array<Field> {
    const fieldsToUpdate = new Array<Field>();
    
    this.fieldSettingsMap.forEach((setting: Setting, field: Field) => {
      if (setting === Setting.ToUpdate) {
        fieldsToUpdate.push(field);
      }
    });

    return fieldsToUpdate;
  }
}

export {Field, Setting};
export default MultiEditionSettings;