import React from 'react';
import MultiEditionSettings from '../../db/MultiEditionSettings';
import {Field, Setting} from './MultiEditionSettingsPage';

interface MultiEditionSettingsFormProps {
  fieldsSettings: MultiEditionSettings;
  stepSetting: Setting;
  otherSetting: Setting
  onChange(field: Field, stepSetting: Setting, otherSetting: Setting): void; 
  children: any;
}

const MultiEditionSettingsForm = (props: MultiEditionSettingsFormProps) => {
  
  const fieldsArray = [
    {id: "payerName", label: "Recebi (emos) de", field: Field.PayerName},
    {id: "referrerTo", label: "Referente", field: Field.ReferrerTo},
    {id: "paidValue", label: "Valor", field: Field.PaidValue},
    {id: "payerAddress", label: "Endereço", field: Field.PayerAddress},
    {id: "date", label: "Data", field: Field.Date},
    {id: "payeeName", label: "Emitente", field: Field.PayeeName},
    {id: "payeeCpfOrRg", label: "CPF/RG", field: Field.PayeeCpfOrRg}
  ];

  return (
    <form name={"multipleedit-form-" + props.stepSetting}
      action="javascript:void(0);"
      noValidate >
      
      {fieldsArray.map((field) => {
        return (
        <div className="form-control">
          <label className="cursor-pointer label" htmlFor={field.id}>
            <span className="label-text">{field.label}</span>
            <input 
              name={field.id}
              id={field.id}
              type="checkbox"
              className="checkbox"
              checked={props.fieldsSettings.getFieldSetting(field.field) === props.stepSetting}
              onChange={() => props.onChange(field.field, props.stepSetting, props.otherSetting)}
              disabled={props.fieldsSettings.getFieldSetting(field.field) === props.otherSetting}
              />
          </label>
        </div>
        )
      })}
      
      <div className="action-bar">
        {props.children}
      </div>
    </form>
  )
}

export default MultiEditionSettingsForm;