const writtenNumber = require('written-number');

function isInt(n: any){
  return Number(n) === n && n % 1 === 0;
}

function isFloat(n: any){
  return Number(n) === n && n % 1 !== 0;
}

{/* <span>{writtenNumber(props.receipt.paidValue, {lang: 'pt'}) + ' reais'}</span> */}

// 22.25 - vinte e dois reais e 25 centavos
// 20.235 - vinte reais e 24 centavos
// 25 - vinte e cinco reais
// 31.1 - trinta e um reais e 10 centavos

function moneyToWords(number: any) {
  let numberConverted;
  if (typeof number == 'string') {
    numberConverted = parseFloat(number);
  }

  if (!numberConverted) {
    console.error(`parameter ${number} is not a number`);
    return number;
  }

  if (isInt(numberConverted)) {
    return `${writtenNumber(numberConverted, {lang: 'pt'})} reais`;
  }

  if (isFloat(numberConverted)) {
    const decimalPart = (numberConverted % 1);
    const integerPart = numberConverted - decimalPart;
    const decimalAsInteger = decimalPart * 100;
    return `${writtenNumber(integerPart, {lang: 'pt'})} reais e ${writtenNumber(decimalAsInteger, {lang: 'pt'})} centavos`;
  }

  console.error(`parameter ${number} is not a number`);
  return number;
}

export {moneyToWords}