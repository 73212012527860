import React from 'react';
import { observer } from "mobx-react";
import {moneyToWords} from '../../utils/MoneyToWords'

import './Receipt.css';

const ReceiptStub = observer((props) => {

    return (
      <div className="receipt-container">
        
        <div className="flex">
          <div className="flex-1">
            <label>Numero:</label>
            <span>{props.receipt.id}</span>
          </div>
          
          <div className="flex-1">
            <label>Valor:</label>
            <span>R${props.receipt.paidValue}</span>
          </div>
        </div>

        <div className="receipt-payer">
          <div>
            <label>Recebi (emos) de:</label>
            <span>{props.receipt.payerName}</span>
          </div>

          <div>
            <label htmlFor={"payerAddress-" + props.index}>Endereço:</label>
            <span>{props.receipt.payerAddress}</span>
          </div>

          <div>
            <label htmlFor={"paidValueAsText-" + props.index}>A importância de:</label>
            {props.receipt.paidValue && 
              <span>{moneyToWords(props.receipt.paidValue)}</span>
            }
          </div>

          <div>
            <label htmlFor={"referrerTo-" + props.index }>Referente</label>
            <span>{props.receipt.referrerTo}</span>
          </div>
        </div>

        <div className="receipt-date">
          <label htmlFor={"date-" + props.index}>Data</label>
          {props.date}
        </div>

      </div>
    );
  }
)
export default ReceiptStub;