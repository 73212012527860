import React, {useState, useEffect} from 'react';
// import './css/Normalize.css';
// import './css/SkeletonCustomizedByMe.css';
import './css/Common.css';
import './css/App.css';

import ReceiptCardsPage from './pages/ListReceiptCards/ListReceiptCardPage';
import NewEditReceiptPage from './pages/NewEditReceipt/NewEditReceiptPage';
import ReceiptsToPrintPage from './pages/ReceiptsToPrint/ReceiptsToPrintPage';
import MultipleEditionSettings from './pages/MultiEditionSettings/MultiEditionSettingsPage';
import MultiEditionPage from './pages/MultiEdition/MultiEditionPage';
import SettingsPage from './pages/Settings/SettingsPage';

import Database from './db/Database';

declare global {
  interface Window { Database: any; }
}      

// #receipt-list
// #receipt
// #receipt/102
// #receipt-clone/100
// #receipts-to-print?receiptIds=[1,2,3,4,5,6]

interface RouteTo {
  path: string;
  pathId: string;
  queryString: Map<string,string>;
}

function pathSplitted() {
  const hash = window.location.hash.substr(1);
  
  var params: Map<string,string> = new Map();
  
  const splitted = hash.split('/');
  let path = splitted[0] || '';
  let pathId = splitted[1] || '';
  
  if (hash.includes('?')) {
    const secondSplit = hash.split('?');
    path = secondSplit[0];
  
    const hashQueryString = secondSplit[1] || '';
    hashQueryString.split('&').map(hk => { 
      let temp = hk.split('='); 
      params.set(temp[0], temp[1]);
    });
  }

  return {
    path: path,
    pathId: pathId,
    queryString: params
  } as  RouteTo
}

const App = () => {

  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(pathSplitted().path);
  const [identifier, setIdentifier] = useState(parseInt(pathSplitted().pathId));
  const [hashQueryString, setHashQueryString] = useState<Map<string,string>>(pathSplitted().queryString);
  function locationHashChanged() {
    const object = pathSplitted();
    setCurrentPage(object.path);
    setIdentifier(parseInt(object.pathId));
    setHashQueryString(object.queryString);
  }
  window.onhashchange = locationHashChanged;

  const [database, setDatabase] = useState<Database>(new Database);
  
  useEffect(() => {
    async function connectDB() {
      await database.connect();
      setLoading(false);

      window.Database = database;
    };
    connectDB();
  }, []);

  return (
    <div className="rootApp">
      <main>
        {
          (() => {

            if (loading) {
              return <span>Carregando...</span>
            }

            if (!currentPage ||  currentPage === 'receipt-list') {
              return <ReceiptCardsPage 
                database={database}>
              </ReceiptCardsPage>
            }

            if (currentPage === 'receipt-clone' && identifier) {
              return <NewEditReceiptPage
                receiptId={identifier}
                database={database}
                toClone
              />
            }

            if (currentPage === 'receipt' && identifier) {
              return <NewEditReceiptPage
                receiptId={identifier}
                database={database}
              />
            }

            if (currentPage === 'receipt') {
              return <NewEditReceiptPage
                database={database}
              />
            }
            
            const receiptIds = hashQueryString.get('receiptIds');
            if (currentPage === 'receipts-to-print' && receiptIds != undefined) {
              return <ReceiptsToPrintPage 
                database={database}
                receiptIds={JSON.parse(receiptIds)}
                />
            }

            const current = hashQueryString.get('current');
            if (currentPage === 'multi-edition' && receiptIds != undefined) {
              return <MultiEditionPage
                database={database}
                receiptIds={JSON.parse(receiptIds)}
                currentIndex={current ? parseInt(current) : 0}
                />
            }

            if (currentPage === 'multi-edition-settings') {
              return <MultipleEditionSettings 
                database={database}
                />
            }

            if (currentPage === 'settings') {
              return <SettingsPage/>
            }

            return "NOTFOUND"
          })()
        }
      </main>

      <footer className="bottom-app-bar no-printable">
        <a className={"btn btn-ghost px-1 " + (currentPage == 'receipt-list' || currentPage == '' ? 'opacity-50' : '')} href="#receipt-list" >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd" />
          </svg>
          <label className="container text-2xs">Lista</label>
        </a>
        <a className={"btn btn-ghost px-1 " + (currentPage == 'receipt' ? 'opacity-50' : '')} href="#receipt" >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd" />
          </svg>
          <label className="container text-2xs">Novo</label>
        </a>
        <a className={"btn btn-ghost px-1 " + (currentPage == 'settings' ? 'opacity-50' : '')} href="#settings" >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z" />
          </svg>
          <label className="container text-2xs">Configurações</label>
        </a>
        
      </footer>
    </div>
  );
};

export default App;
