import React, { useState } from 'react';
import { observer } from "mobx-react";

import './ReceiptCard.css';
import Receipt from '../../db/Receipt';

interface ReceiptCardInterface {
  receipt: Receipt;
  enableSelection: boolean;
  isSelected: boolean;
  onRemove (receiptId: number): void;
  onSelectionChange (isSelected: boolean): void;
}

const ReceiptCard = observer((props: ReceiptCardInterface) => {

    return (
      <div className="receipt-card">

        <div className="receipt-card__header">
          <span className="receipt-card__title">{props.receipt.payerName || "..."}</span>

          <div className="receipt-card__check_container">
            <div className="receipt-card__check" 
            onClick={() => props.onSelectionChange(!props.isSelected)}>
              {props.enableSelection && (<svg width="24px" height="24px"  
                viewBox="0 0 24 24"
                className="receipt-card__check">
                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path>
              </svg>)}

              {props.isSelected && (<svg width="24px" height="24px"
                viewBox="0 0 24 24"
                className="receipt-card__check">
                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z">
                </path>
              </svg>)}
            </div>
          </div>
        </div>
        
        <div className="receipt-card__content">
          <span>{props.receipt.referrerTo || "..."}</span>
          
        </div>

        <div className="receipt-card__bottom">
          <div className="receipt-card__price">
            <span>R$ {props.receipt.paidValue || "?"}</span>
          </div>

          <div className="line-separator"></div>

          <div className="receipt-card__action-bar">
            <a className="receipt-card__button" href={"#receipt/" + props.receipt.id}>
              <i className="fas fa-edit"></i>
            </a>
            <a className="receipt-card__button" href={"#receipt-clone/" + props.receipt.id}>
              <i className="fas fa-clone"></i>
            </a>
            <a className="receipt-card__button" onClick={() => {
              const answer = window.confirm("Realmente deseja remover esse recibo?");
              if (answer === true) {
                props.onRemove(props.receipt.id!)};
              }
            }>
              <i className="fas fa-trash"></i>
            </a>
          </div>
        </div>

      </div>
    );
  }
)
export default ReceiptCard;