import React, {useState, useEffect} from 'react';
import Database from '../../db/Database';

import MultiEditionSettingsForm from './MultiEditionSettingForm';
import { Field, Setting } from '../../db/MultiEditionSettings';
import MultiEditionSettings from '../../db/MultiEditionSettings';

interface MultiEditionSettingsPageProps {
  database: Database;
  nextPageHash?: string;
}

const MultiEditionSettingsPage = (props: MultiEditionSettingsPageProps) => {
  const [multiEditionSettings, setMultiEditionSettings] = useState<MultiEditionSettings>(new MultiEditionSettings());

  const [step, setStep] = useState<number>(1);
  
  useEffect(() => {
    props.database.getMultiEditionSettings()
    .then((multiEditionSettings) => {
      setMultiEditionSettings(multiEditionSettings);
    })
    .catch(() => {
      console.error('Fail to load fieldsSettings');
    })
  }, [])

  function setFieldSetting(field: Field, setting: Setting) {
    const newMESettings = MultiEditionSettings.Instance(multiEditionSettings.fieldSettings);
    newMESettings.setFieldSetting(field, setting);
    setMultiEditionSettings(newMESettings);
  }

  function handleOnChange(field: Field, stepSetting: Setting, otherSetting: Setting) {
    const setting = multiEditionSettings.getFieldSetting(field);
    
    if (setting === stepSetting) {
      setFieldSetting(field, Setting.None);
    }

    if (setting === otherSetting) {
      setFieldSetting(field, stepSetting);
    }

    if (setting === Setting.None) {
      setFieldSetting(field, stepSetting);
    }
  }
  
  function handleClickOnBack() {
    if (step == 1) {
      window.history.back();
    } else {
      setStep(step - 1);
    }
  }

  function handleClickOnClose() {
    window.history.back();
  }

  function handleSaveAndNext() {
    props.database.saveMultiEditionSettings(multiEditionSettings)
      .then(() => {
        if (props.nextPageHash) {
          window.location.hash = props.nextPageHash
        } else {
          window.history.back();
        }
      })
      .catch((err) => {
        alert("Erro ao tentar gravar.")
        console.log(err);
      })
  }

  return (
   <div>
     <div className="flex flex-col pb-2">
        <div className="navbar shadow-lg bg-neutral text-neutral-content rounded-box">
          
          <div className="flex-none">
            <button className="btn btn-square btn-ghost" onClick={handleClickOnBack}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
            </svg>
            </button>
          </div>

          <div className="flex-1 text-2xl">
            {step===1 && "1 de 2 campos de referência"}
            {step===2 && "2 de 2 campos a mudar"}
          </div>

          <div className="flex-none">
            <button className="btn btn-square btn-ghost" onClick={handleClickOnClose}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-6 h-6 stroke-current text-error">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
            </button>
          </div>
        </div>
      </div>

      {step===1 &&
      <div>
        <p></p>
        <p>Selecione as informações que irão te ajudar a identificar qual recibo você estará editando.</p>
        <MultiEditionSettingsForm
          fieldsSettings={multiEditionSettings}
          stepSetting={Setting.ToIdentify}
          otherSetting={Setting.ToUpdate}
          onChange={handleOnChange}
        >
        <button className="btn btn-block" onClick={() => setStep(2)}>
          Continuar
        </button>
        </MultiEditionSettingsForm>
      </div>
      }

      {step===2 &&
      <div>
        <p></p>
        <p>Selecione quais as informações que você quer atualizar.</p>
        
        <MultiEditionSettingsForm
          fieldsSettings={multiEditionSettings}
          stepSetting={Setting.ToUpdate}
          otherSetting={Setting.ToIdentify}
          onChange={handleOnChange}
        >
        <button className="btn btn-block" onClick={handleSaveAndNext}>
          Salvar e seguir
        </button>
        </MultiEditionSettingsForm>
      </div>
      }
   </div>
  )
}

export {Field, Setting}
export default MultiEditionSettingsPage;