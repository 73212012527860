import React, { useState } from 'react';

import Receipt  from '../../db/Receipt';
import Modal from '../../components/modal/Modal';

interface ListReceiptCardHeaderProps {
  isSelectModeOn: boolean;
  receiptsSelected: Map<number, Receipt>;
  onClickToEnableSelectMode(): void;
  onClickToDisableSelectMode(): void;
  onClickToCloneSelectedReceipts(): void;
  onClickToDeleteSelectedReceipts(): void;
  onClickToPrintSelectedReceipts(): void;
  onClickToEditSelectedReceipts(): void;
  onChangeOfPayerNameToFilter(name: string): void;
}

const ListReceiptCardHeader = (props: ListReceiptCardHeaderProps) => {
  const [pageModalIsVisible, setPageModalIsVisible] = useState<boolean>();

  function handleCloseSelectionMode() {
    props.onClickToDisableSelectMode();
  }

  return (
    <div>
      <Modal 
        show={pageModalIsVisible} 
        handleClose={() => {setPageModalIsVisible(false)}}>
        <a onClick={() => {
            props.onClickToEnableSelectMode();
            setPageModalIsVisible(false); 
          }}>
          Selecionar recibos
        </a>
      </Modal>

      {!props.isSelectModeOn && (
        <div className="flex flex-col pb-2">
          <div className="navbar shadow-lg bg-neutral text-neutral-content rounded-box">
            <div className="flex-1 px-2 mx-2 text-2xl">
              Recibos Facil
            </div>
            
            <div className="flex-none">
                <input placeholder="Pesquise por nome" className="input input-ghost text-xl" type="search"
                  onChange={(e) => props.onChangeOfPayerNameToFilter(e.target.value)}/>
            </div>

            <div className="flex-none">
              <button className="btn btn-square btn-ghost" onClick={() => setPageModalIsVisible(true)}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      )}

      {props.isSelectModeOn && (
        <div className="flex flex-col pb-2">
          <div className="navbar shadow-lg bg-neutral text-neutral-content rounded-box">
            
            <div className="flex-none">
              <button className="btn btn-square btn-ghost" onClick={handleCloseSelectionMode}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-6 h-6 stroke-current text-error">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12">
                </path>
              </svg>
              </button>
            </div>

            <div className="flex-1 text-2xl">
              {props.receiptsSelected.size === 0 && ("Selecionar recibos")}
              {props.receiptsSelected.size > 0 && (
                props.receiptsSelected.size
              )}
            </div>

            <div className="flex-none">
              {props.receiptsSelected.size > 0 && (
                <div>
                  <button className="btn btn-ghost px-1" onClick={props.onClickToPrintSelectedReceipts}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z" />
                    </svg>
                    <label className="container text-2xs">Imprimir</label>
                  </button>
                  <button className="btn btn-ghost px-1" onClick={props.onClickToEditSelectedReceipts}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                    </svg>
                    <label className="container text-2xs">Editar múltipos</label>
                  </button>
                  <button className="btn btn-ghost px-1" onClick={props.onClickToCloneSelectedReceipts}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2" />
                    </svg>
                    <label className="container text-2xs">Duplicar</label>
                  </button>
                  <button className="btn btn-ghost px-1" onClick={props.onClickToDeleteSelectedReceipts}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <label className="container text-2xs">Deletar</label>
                  </button>                  
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ListReceiptCardHeader;