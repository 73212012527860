import Receipt  from './Receipt';
import {Field, Setting} from '../pages/MultiEditionSettings/MultiEditionSettingsPage';
import UserSetting from './UserSetting';
import MultiEditionSettings from './MultiEditionSettings';
import { receiptAsExamples } from './DatabaseExampleRows'

class Database {
  
  #db: any;

  connect() {
    return new Promise((resolve, reject) => {
      const dbOpenReq = indexedDB.open('EasyReceipts', 1);
  
      dbOpenReq.onerror = (err) => {
        console.error('error creating/connecting to db', err);
        reject('Failed to connected/create/update DB');
      };
  
      dbOpenReq.onsuccess = (event: any) => {
        this.#db = event.target?.result;
        console.info('connected successfuly to DB')
        resolve("Connected");
      };
  
      dbOpenReq.onupgradeneeded = (event: any) => {
        this.#db = event.target?.result;
  
        const receiptStore = this.#db.createObjectStore('receipt', {
          autoIncrement: true
        });
        receiptStore.createIndex("date", "date", { unique: false });
        
        const userSettingStore = this.#db.createObjectStore('userSetting', {keyPath: 'id'});

        const transaction = event.target.transaction;
        receiptAsExamples.map(receipt => {
          const addRequest = transaction.objectStore('receipt').add(receipt);
          addRequest.onsuccess = function() {console.log(`Receipt popuplated [${receipt}]`)};
        })
      };
    });
  }

  executeWithinTransaction(table: string, changes: {(objectStore: IDBObjectStore) : IDBRequest}) {
    return new Promise((resolve, reject) => {
      const transaction = this.#db.transaction([table], 'readwrite');
      const objectStore = transaction.objectStore(table);
      
      const request = changes.apply(undefined, [objectStore]);
      
      request.onerror = function(event: any) {
        console.error(`Error on executing transaction on table ${table}`);
        reject();
      };
      
      request.onsuccess = function(event: any) {
        resolve(true);
      };
    })
  }

  fetchById <Type> (table: string, id: any) : Promise<Type | undefined>{
    return new Promise((resolve, reject) => {
      var transaction = this.#db.transaction([table]);
      var objectStore = transaction.objectStore(table);
      
      var request = objectStore.get(id);
      
      request.onerror = function(event: any) {
        console.error(`Error on fiding ${id} on table ${table}`);
        reject();
      };
      
      request.onsuccess = function(event: any) {
        if (!request.result) {
          resolve(undefined);
        } else {
          const result = request.result;
          result.id = id;
          resolve(result as Type);
        }
      };
    })
  }

  async saveMultiEditionSettings(multiEditionSettings: MultiEditionSettings) {
    const userSettingME = await this.fetchById('userSetting', 'multi-edition');
    
    return this.executeWithinTransaction('userSetting', (objectStore: IDBObjectStore) => {
      const userSetting = new UserSetting();
      userSetting.id = 'multi-edition';
      userSetting.value = JSON.stringify(Array.from(multiEditionSettings.fieldSettings));

      var request;
      if (!userSettingME) {
        request = objectStore.add(userSetting);
      } else {
        request = objectStore.put(userSetting);
      }
      return request;
    })
  }

  getMultiEditionSettings() {
    return new Promise<MultiEditionSettings>(async (resolve, reject) => {
      const userSetting = await this.fetchById<UserSetting>('userSetting', 'multi-edition');

      if (!userSetting) {
        resolve(new MultiEditionSettings());
      } else {
        resolve(MultiEditionSettings.Instance(new Map<Field, Setting>(JSON.parse(userSetting.value || ''))));
      }
    })
  }

  addReceipt(receipt: Receipt) {
    return this.executeWithinTransaction('receipt', (objectStore: IDBObjectStore) => {
      return objectStore.add(receipt);
    })
  };

  updateReceipt(receipt: Receipt, id: number) {
    if (!id) {
      throw new Error('Id must not be undefined!')
    }
    return this.executeWithinTransaction('receipt', (objectStore: IDBObjectStore) => {
      return objectStore.put(receipt, id);
    })
  }

  deleteReceipt(id: number) {
    return this.executeWithinTransaction('receipt', (objectStore: IDBObjectStore) => {
      return objectStore.delete(id);
    })
  };

  batchDelete(ids: IterableIterator<number>) {
    return new Promise<void>((resolve, reject) => {
      const transaction = this.#db.transaction(['receipt'], "readwrite");
      
      let nextId = ids.next();
      while (!nextId.done) {
        transaction.objectStore('receipt').delete(nextId.value);;
        nextId = ids.next();
      }

      transaction.oncomplete = function(event: any) {
        //GOOD CASE TO UNIT TESTE, SUCCESS FOR DELETE OR FOR NOT FOUND?
        resolve();        
      };
      transaction.onerror = function(event: any) {
        console.error('Error on deleting batch of receipts');
        reject();
      };
    })
  };

  batchAdd(receipts: IterableIterator<Receipt>) {
    return new Promise<void>((resolve, reject) => {
      const transaction = this.#db.transaction(['receipt'], "readwrite");
      
      let nextReceipt = receipts.next();
      while (!nextReceipt.done) {
        transaction.objectStore('receipt').add(nextReceipt.value);;
        nextReceipt = receipts.next();
      }

      transaction.oncomplete = function(event: any) {
        //GOOD CASE TO UNIT TESTE, SUCCESS FOR DELETE OR FOR NOT FOUND?
        resolve();        
      };
      transaction.onerror = function(event: any) {
        console.error('Error on adding batch of receipts');
        reject();
      };
    })
  };

  arrayAdd(receipts: Receipt[]) {
    return new Promise<void>((resolve, reject) => {
      const transaction = this.#db.transaction(['receipt'], "readwrite");
      
      for (let i = 0; i < receipts.length; i++) {
        transaction.objectStore('receipt').add(receipts[i]);;
      }

      transaction.oncomplete = function(event: any) {
        //GOOD CASE TO UNIT TESTE, SUCCESS FOR DELETE OR FOR NOT FOUND?
        resolve();        
      };
      transaction.onerror = function(event: any) {
        console.error('Error on adding batch of receipts');
        reject();
      };
    })
  };

  //TODO EVOLUIR PARA TRATAR CASOS DE ERRO
  getReceipts(ids: [number]) {    
    return new Promise<Receipt[]>((resolve, reject) => {
      const receipts =  new Array<Receipt>();
      
      ids.map((id: number, index: number) => {
        this.getReceipt(id)
          .then((receipt) => {
            receipts.push(receipt);
            if (index === ids.length - 1) {
              resolve(receipts);
            }
          })
      })
    })
  }
  //TODO: ADD class type RECEIPT when returning a receipt
  getReceipt(id: number) {
    return new Promise<Receipt>(async (resolve, reject) => {
      const receipt = await this.fetchById<Receipt>('receipt', id);

      if (!receipt) {
        console.warn('Receipt not found');
        resolve(new Receipt());
      } else {
        resolve(receipt);
      }
    })
  };

  // TODO RETURN RECEIPT OBJECT LIST
  getAllReceipts() {
    return new Promise<Receipt[]>((resolve, reject) => {
      var transaction = this.#db.transaction(['receipt']);
      var objectStore = transaction.objectStore('receipt');
      var receipts: Receipt[] = [];

      objectStore.openCursor().onsuccess = function(event: any) {
        var cursor = event.target.result;
        if (cursor) {
          const receiptModel = new Receipt();
    
          receiptModel.payerName = cursor.value.payerName;
          receiptModel.payerAddress = cursor.value.payerAddress;
          receiptModel.referrerTo = cursor.value.referrerTo;
          receiptModel.paidValue = cursor.value.paidValue;
          receiptModel.date = cursor.value.date;
          receiptModel.payeeName = cursor.value.payeeName;
          receiptModel.payeeCpfOrRg = cursor.value.payeeCpfOrRg;
          receiptModel.payeeAddress = cursor.value.payeeAddress;
          receiptModel.id = cursor.key;
          
          receipts.push(receiptModel);
          cursor.continue();
        }
        else {
          console.log("No more entries!");
          resolve(receipts);
        }
      };
    })
  }

  db() {
    return this.#db;
  }
}

//await database.connect();
export default Database;

