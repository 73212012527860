import React, { useState, useEffect } from 'react';
import Receipt  from '../../db/Receipt';
import ReceiptCard from '../../components/receiptcard/ReceiptCard';

import Modal from '../../components/modal/Modal';

interface ByMonthReceiptCardProps {
  title: string;
  receipts: Receipt[];
  enableSelection: boolean;
  receiptsSelected: Map<number, Receipt>;
  payerNameFiltering: string;
  onRemove(receiptId: number): void;
  onSelectionChange(receiptId: number, receipt: Receipt | undefined): void;
  onSelectAll(receipts: Receipt[]) : void;
}

const ByMonthReceiptCard = (props: ByMonthReceiptCardProps) => {

  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [modalIsVisible, setModalIsVisible] = useState<boolean>();

  function handleSelectAll() {
    props.onSelectAll(props.receipts);
    setModalIsVisible(false);
  }
  return (
    <div className="receipts-by-month-container" key={props.title}> 
      
      <Modal show={modalIsVisible} handleClose={() => {setModalIsVisible(false)}}>
        <a onClick={handleSelectAll}>
          Selecionar todos
        </a>
      </Modal>

      <div className="receipts-by-month__header">
        <div className="receipts-by-month__header__left">
          <i className={"fas " +
              (collapsed ? "fa-chevron-right " : "fa-chevron-down ")
            }
            onClick={() => setCollapsed(!collapsed)}
          ></i>
          <span className="receipts-by-month__title">{props.title}</span> 
          <span className="receipts-by-month__details">{props.receipts.length}</span>
        </div>
        <div className="receipts-by-month__header__right">
          <i 
            className="fas fa-ellipsis-v receipts-by-month__menu" 
            onClick={() => setModalIsVisible(true)}></i>
        </div>
      </div>

      <div className="receipts-by-month__list">
      {
        props.receipts.map((receipt) => {
          if (collapsed) {
            return false;
          }
  
          if (props.payerNameFiltering && (!receipt.payerName || !receipt.payerName.includes(props.payerNameFiltering))) {
            return;
          }

          return <ReceiptCard
              receipt={receipt}
              onRemove={props.onRemove}
              enableSelection={props.enableSelection}
              isSelected={props.receiptsSelected.get(receipt.id!) !== undefined}
              onSelectionChange={(iSelected) => props.onSelectionChange(receipt.id!, iSelected ? receipt : undefined)}
            />
        })
      }
      </div>
    </div>
  );
}

export default ByMonthReceiptCard;