import React, { useState, useEffect } from 'react';
import Receipt from '../../components/receipt/Receipt';
import ReceiptStub from '../../components/receipt/ReceiptStub';
import Database from '../../db/Database';

interface ReceiptsToPrintPageProps {
  database: Database
  receiptIds: [number]
}

const ReceiptsToPrintPage = (props: ReceiptsToPrintPageProps) => {
  const database = props.database;
  const [receipts, setReceipts] = useState<any[]>([]);

  useEffect(() => {
    async function fetchReceipts() {
      const receipts = await database.getReceipts(props.receiptIds);
      setReceipts(receipts);
    };
    fetchReceipts();
  }, []);

  return (
    <div className="">
      <div className="flex flex-col pb-2 no-printable">
        <div className="navbar shadow-lg bg-neutral text-neutral-content rounded-box">
          
          <div className="flex-none">
            <button className="btn btn-square btn-ghost" onClick={() => (window.history.back())}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
            </svg>
            </button>
          </div>

          <div className="flex-1 text-2xl">
            Imprimir
          </div>
        </div>
      </div>

      
      <div className="action-top-bar">
        <button className="btn no-printable" onClick={window.print}>
          Imprimir
        </button>
      </div>
      
      <div className="">
        <h2 className="text-4xl mb-2">Canhotos</h2>
        {receipts.map((receipt) => (
          <div key={receipt.id}>
            <ReceiptStub
              key={receipt.id}
              index={receipt.id}
              payee={receipt.payeeName}
              payeeCpfOrRg={receipt.payeeCpfOrRg}
              payeeAddress={receipt.payeeAddress}
              date={receipt.date}
              receipt={receipt}
            />
          </div>
        ))} 

        <h2 className="text-4xl mb-2">Recibos</h2>
        {receipts.map((receipt) => (
          <div key={receipt.id}>
            <Receipt
              key={receipt.id}
              index={receipt.id}
              payee={receipt.payeeName}
              payeeCpfOrRg={receipt.payeeCpfOrRg}
              payeeAddress={receipt.payeeAddress}
              date={receipt.date}
              receipt={receipt}
            />
          </div>
        ))} 
      </div>
    </div>
  );
}

export default ReceiptsToPrintPage;